/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { makeStyles } from '@material-ui/core';
import { shape, string, func } from 'prop-types';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { trackEvent } from '../../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';
import SiteWideBannerUI from './SiteWideBannerUI';
import useUTMRules from '../../../../helpers/DynamicRules/hooks/useUTMRules';
import useUserType from './UseUserType';

const useStyles = makeStyles(() => ({
    link: {
        textDecoration: 'none',
    },
}));

const SiteWideBannerContainer = ({ bannerData, track }) => {
    const showUserType = bannerData?.show_to_user_types || [];
    const enableSiteWideBanner = useUserType(showUserType);
    const isLink = bannerData?.linking?.link?.href.length;
    const classes = useStyles();
    const link = bannerData?.linking?.link;
    const ga = bannerData?.linking;

    const utmPassed = useUTMRules(bannerData);
    if (!utmPassed) {
        return <></>;
    }
    const handleTracking = (cat, act, lab) => {
        track({
            eventCategory: cat || '',
            eventAction: act || '',
            eventLabel: lab || '',
        });
    };
    return (
        enableSiteWideBanner
        && (
            <div data-testid="bannerv2">
                {isLink ? (
                    <Link
                        className={classes.link}
                        to={link.href}
                        title={link.title}
                        copy={link.title}
                        data-ga-category={link.tracking_event_category}
                        data-ga-action={link.tracking_event_action}
                        data-ga-label={link.tracking_event_label}
                        onClick={() => {
                            handleTracking(
                                ga.tracking_event_category || '',
                                ga.tracking_event_action || '',
                                ga.tracking_event_label || '',
                            );
                        }}
                    >
                        <SiteWideBannerUI bannerData={bannerData} clickHandler={handleTracking} />
                    </Link>
                ) : (
                    <SiteWideBannerUI bannerData={bannerData} clickHandler={handleTracking} />
                )}
            </div>
        )
    );
};

SiteWideBannerContainer.defaultProps = {
    bannerData: {},
};

SiteWideBannerContainer.propTypes = {
    bannerData: shape({
        about_the_banner_message_: string,
        message: string,
        title: string,
        styling: shape({
            background_color: shape({
                color: string,
            }),
            cta_font: string,
        }),
    }),
    track: func.isRequired,
};
const mapDispatchToProps = (dispatch) => ({
    track: bindActionCreators(trackEvent, dispatch),
});
const enhance = compose(
    connect(null, mapDispatchToProps),
);
export default enhance(SiteWideBannerContainer);
